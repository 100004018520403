.modal-content{
    width: 100% !important;
    border-radius: 1.5rem !important;
    padding-bottom: 12px !important;
}

.confirm-btn {
    background-image: linear-gradient(to right, #7f66ee, #398bfc);
    color: black;
    height: 40px;
    padding: 9px;
    border-radius: 14px;
    align-items: center;
    width: 8rem;
}

.web-cam{
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
}

.webcam{
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
}