
.dark{
    background-color: #24243e !important;

//login form

.main-section {
    background-color: #24243e !important;
}

.text-box3 {
    background-color: #3b4064 !important;
    border: none !important;
}

.account-txt {
    color: #ffffff;
}

.text1 {
    color: #ffffff;
}

.MuiFilledInput-root {
    background-color: #3b4064 !important;
    border-bottom: 2px solid #398bfc;
}

.MuiInputBase-input {
    background-color: #3b4064 !important;
    color: #ffffff !important;
}

.MuiInputBase-input .MuiFilledInput-input{
    background-color: #3b4064 !important;
    border-bottom: 1px solid #398bfc;
}

.header{
    background-color: #3b4064 !important;
}
.list-group{
    height: 80vh !important;
    background-color: #3b4064 !important;
    // width: 300px;
}
.list-group-item {
    border: none !important;
    background-color: #3b4064 !important;
    color: #a7a7b2 !important;
    
}
.list-group-item-action{
    color: #a7a7b2 !important;
    // padding: 0.8rem .5rem .8rem 1rem;
}
.list-group-item-action:hover{
    border: 1px solid #3b4064 !important;
    background-color: #24243e !important;
    // padding: 0.8rem .5rem .8rem 1rem;
}
.list-group-item.active {
    background-color: #24243e !important;
    border: 1px solid #3b4064 !important;
    color: #398bfc !important;
}

.table thead th {
    border-bottom: 2px solid #dee2e6;
    color: white !important;
}

.MuiPaper-root {
    background-color: #3b4064;
    border: none !important;
}

.modal-postion {
    background-color: #3b4064;
    border: none !important;
}

.selects{
    background-color: #3b4064 !important;
}

.file-name-o{
    color: #a7a7b2 !important;
}

.table th, .table td {
    color: #a7a7b2 !important;
}

.folder {
    color: #a7a7b2 !important;
}

.folder-icon {
    color: #398bfc;
}
.up-btn {
    background-image: linear-gradient(to right, #5e44d3 1%, #398bfc);;
}

.popup-text {
    color: #ffffff;
}

.header-icons {
    color: white;
}

.upload-text {
    color: #ffffff;
}

//profile section
.text{
    color: #a7a7b2 !important;
}
.sub-text {
    color: #ffffff !important;
}

.info-text {
    color: #ffffff;
}
.seed-text {
color: rgba(0,0,0,0.75);
}
//select plan

.div-section {
    background-color: #24243e !important;
}
.card{
    background-color: #3b4064 !important;
}
.bold-head {
    color: #ffffff !important;
}
.purchase-head {
    color: #ffffff !important;
}
.card-txt {
    color: #a7a7b2;
}
.card-bold {
    color: #ffffff;
}

//buy plan

.head-txt {
    color: #398bfc;
}

.btn-create {
    background-color: #3b4064;
    color: #398bfc;
    border: none !important;
}

.text-one {
    color: #ffffff;
}

.MuiFormControl-root {
    background-color: #3b4064 !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.MuiFormLabel-root {
    color: #a7a7b2;
    padding-left: 4px !important;
    margin-top: 2px !important;
}

.forgot-txt {
    color: #a7a7b2;
}

.footer-txt {
    color: #a7a7b2;
}

.MuiInputBase-root .MuiFilledInput-root .MuiFilledInput-underline .MuiInputBase-formControl{
    background-color: #3b4064 !important;
}

.form-control {
    background-color: #a7a7b2 !important;
}

.text-dark {
    color: #ffffff !important;
}
.modal-content {
    background-color: #3b4064 !important;
}

.profile-icons{
    color: #a7a7b2 !important;
}

.messbox{
    color: #a7a7b2 !important;
}

.keyIcon{
    color: #a7a7b2 !important;
}

.typeIcon{
    color: #a7a7b2 !important;
}

.heading{
    color: #a7a7b2 !important;
}

.btn-box{
    background-color: #3b4064 !important;
}

.btn-txt{
    color: #ffffff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px #3b4064 inset !important;
    -webkit-box-shadow: 0 0 0 30px #3b4064 inset !important;
    border-top-left-radius: 0px !important; 
    border-top-right-radius: 0px !important;
    -webkit-text-fill-color: #ffffff;
}

.MuiInputLabel-root {
    z-index: 1000;
    color: #a7a7b2 !important;
    // margin-top: -5px !important;
}
.country {
    background: #3b4064;
}
.lock-Icon{
    top: 12px !important;
    left: 9px !important;
    color: #a7a7b2 !important;
}

.lock-Icon3{
    top: 155px !important;
    left: 9px !important;
    color: #a7a7b2 !important;
}

.lock-Icon5{
    top: 83px !important;
    left: 9px !important;
    color: #a7a7b2 !important;
}

.reset-eye {
    top: 12px !important;
    color: #a7a7b2;
}

.reset-eye2 {
    top: 83px !important;
    color: #a7a7b2;
}

.share-menu-icons {
    color: #a7a7b2 !important;
}

.faq-box2 {
    background-color: #3b4064 !important;
    border: none !important;
}

.add-ppl-txt{
    color: #ffffff !important;
    padding: 0px;
}

.faq-txt {
    color: #a7a7b2 !important;
}

.p-5 {
    color: white !important;
}

.search-input .MuiInputBase-input{
    background: #24243e !important;
    border-radius: 10px !important;
}

.search-btn{
color: #a7a7b2 !important;
right: 1rem;
}
.input-box{
    background-color: #3b4064;
    color: #ffffff;
}
.feedback-modal h1{
    color: #ffffff;
}
.faqs-section .questions p{
    color: #ffffff;
}
}