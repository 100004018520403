#wrapper {
  overflow-x: hidden;
}
.folder {
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #5c6882;
}
.info-text {
  color: #000000;
}
.bar-pos {
  position: absolute;
  top: 8.5rem;
  right: 4rem;
}
.bar-width {
  width: 20rem !important;
}
.bar-cancel-pos {
  z-index: 100;
  position: absolute;
  top: 8rem;
  right: 2rem;
}
.download-bar {
  // width: 22rem !important;
  height: 0.4rem !important;
  margin-top: 15px;
  margin-bottom: 10px;
}
.notificatins {
  width: fit-content;
  height: fit-content;
  background-color: rgba(44, 44, 44, 0.931);
  color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  position: fixed;
  z-index: 100;
  bottom: 8%;
  left: 45%;
  right: 50%;
}
.notify-text {
  font-size: 0.9rem;
}
.up-txt {
  position: absolute;
  top: 7.3rem;
  right: 4rem;
}
.ScrollStyle {
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.down-txt {
  position: absolute;
  top: 9.4rem;
  right: 4rem;
}

.Scroll-email-array {
  height: 3.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
}
.action-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
table thead th,
.table td {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #5c6882;
}
.table thead th {
  border: none !important;
}
.right {
  width: 23%;
}

.Scroll-on-access {
  height: 8rem;
  overflow-y: scroll;
  overflow-x: hidden !important;
  ::-webkit-scrollbar {
    width: 6px;
    display: none !important;
  }
}
.error-text {
  color: #f44336;
}
.folder-icon {
  font-size: 1.6rem;
  margin-right: 0.6rem;
  padding-bottom: 0.16rem;
  color: #5b42ca;
}

.file-name-o {
  overflow: hidden;
}

.doc-icon {
  color: #5781a7;
  font-size: 0.8rem;
  font-weight: 600;
}
.psd-icon {
  color: #316954;
  font-size: 0.8rem;
  font-weight: 600;
}
.jpg-icon {
  color: #c86937;
  font-size: 0.8rem;
  font-weight: 600;
}
.png-icon {
  color: #8261c6;
  font-size: 0.8rem;
  font-weight: 600;
}
.pdf-icon {
  color: #c3152f;
  font-size: 0.8rem;
  font-weight: 600;
}
.xls-icon {
  color: rgb(29, 121, 20);
  font-size: 0.8rem;
  font-weight: 600;
}
.csv-icon {
  color: rgb(170, 131, 5);
  font-size: 0.8rem;
  font-weight: 600;
}
.table th,
.table td {
  vertical-align: middle !important;
}
.help-icon {
  width: 1.6rem;
  height: 1.7rem;
  margin-left: 1rem;
  margin-right: 0.5rem;
}
#sidebar-wrapper {
  min-height: 100vh;
  color: #000 !important;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

.download-div {
  z-index: 100;
  width: 330px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  border-radius: 5px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 15px 4px #918888;
  box-shadow: 0px 0px 15px 4px #918888;
}
.download-remaining {
  font-size: 12px;
  color: gray;
  font-weight: lighter;
}
.download-file-text {
  font-size: 12px;
}
.download-header {
  background-image: linear-gradient(to left, #5b42ca, #398bfc);
  border-radius: 5px 5px 0px 0px;
  height: 50px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 15px;
  left: 4px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.mx-auto {
  padding-left: 10px;
  padding-right: 10px;
}

.drop-icon {
  font-size: 6rem;
  color: #5b42ca;
  position: fixed;
  left: 55%;
  top: 70%;
}
input:checked + .slider {
  background-color: #5b42ca;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5b42ca;
}

input:checked + .slider:before {
  -webkit-transform: translateX(8px);
  -ms-transform: translateX(8px);
  transform: translateX(8px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.table thead th {
  border-bottom: 2px solid #dee2e6;
  color: #5c6882 !important;
}

.access-dropdown {
  width: 10rem !important;
  margin-left: -2rem !important;
}

.frm-ctrl {
  width: 54% !important;
  font-size: 8.5px !important;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.5rem;
}
.list-group-item.active {
  background-color: #ebecef !important;
  border: none !important;
  color: #5b42ca !important;
}
.list-group-item {
  width: 20rem !important;
  border: none !important;
  // padding: 1.3rem .5rem 1.3rem 1rem !important;
}
.list-item {
  width: 24rem !important;
}
.bor-top {
  border-top: 2px solid rgb(230, 227, 227);
}
.sidebar-icons {
  font-size: 1.8rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.helper-text {
  font-size: 0.7rem;
  color: #a7acb9;
}
.header-icons {
  font-size: 1.3rem;
  margin-right: 0.9rem;
  color: rgb(0, 0, 0);
}
.text-box-l {
  width: 100%;
}
.pass-text-box {
  padding: 0;
  margin:0;
  border-bottom: 1px solid gray;
  height: 60px !important;
}
.link2-text {
  width: 85%;
  font-weight: 500;
  color: #3c4043;
  padding: 20px 0;
  padding-left: 6px;
  font-weight: normal;
  background: #f1f3f4;
  border-radius: 5px;
  font-size: 0.7rem;
}
.link-text {
  padding: 20px 0;
  padding-left: 6px;
  font-weight: normal;
  border-radius: 5px;
  font-size: 0.7rem;
}
.copy-text {
  color: #5b42ca;
  font-size: 0.9rem;
  cursor: pointer;
}
.recent tbody {
  min-height: 26rem !important;
}
.recent tbody td {
  padding: 0.9rem !important;
}
.recent-grid-data {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  overflow-x: hidden !important;
  max-height: 75vh !important;
}
table.tableBodyScroll tbody {
  display: block;
  max-height: 18rem !important;
  overflow-y: scroll;
  overflow-x: hidden !important;
}

table.tableBodyScroll thead,
table.tableBodyScroll tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.plus-icon {
  position: fixed;
  right: 4rem;
  bottom: 6%;
  z-index: 100;
  width: 5rem;
  height: 5rem;
  color: #398bfc;
}
.bar {
  width: 12rem;
  height: 0.3rem !important;
  margin-left: 3.3rem;
}
.table th,
.table td {
  padding: 1rem !important;
}
.bar-text {
  font-size: 0.7rem;
  color: #a7acb9;
  margin-left: 3.3rem;
}
.progress-bar {
  background-image: linear-gradient(to left, #5b42ca, #398bfc);
}
.f-1 {
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.15;
  cursor: pointer;
}
.btn-text {
  font-size: 0.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.up-btn {
  background-image: linear-gradient(to left, #5b42ca, #398bfc);
  width: 7rem;
  height: 30px;
  padding: 8px;
  border-radius: 10px;
  align-items: center;
}
.list-group-item-action {
  color: rgb(71, 71, 71) !important;
  padding: 0.8rem 0.5rem 0.8rem 1rem;
}
// #page-content-wrapper {
//   min-width: 100vw;
// }
@media only screen and (max-width: 1601px) {
  .list-group-item-action {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }
  .list-item {
    width: 22rem !important;
  }
  .f-1 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.15;
  }
  .list-group-item {
    width: 20rem !important;
    // padding: .57rem .5rem .57rem 1rem !important;
  }
  table thead th,
  .table td {
    font-size: 14px;
    cursor: pointer;
  }

  .table th,
  .table td {
    padding: 0.7rem !important;
  }
  .folder {
    font-size: 16px;
  }
  .right {
    width: 22%;
  }
  table.tableBodyScroll tbody {
    display: block;
    max-height: 12rem;
    overflow-y: scroll;
    overflow-x: hidden !important;
  }

  table.tableBodyScroll thead,
  table.tableBodyScroll tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
@media only screen and (max-width: 1367px) {
  .right {
    width: 23%;
  }
  .list-item {
    width: 21rem !important;
  }
  .btn-text {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 1281px) {
  .right {
    width: 35%;
  }
  .btn-text {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 1025px) {
  .right {
    width: 25%;
  }
}

@media (max-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  .bar {
    width: 5rem;
    height: 0.3rem !important;
    margin-left: 0.1rem;
  }
  .list-group-item {
    width: 10rem !important;
  }
  .sidebar-icons {
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.2rem;
  }
  .header-icons {
    font-size: 1rem;
    margin-right: 0.2rem;
  }
  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
  .bar-text {
    font-size: 0.5rem;
    margin-left: 0.1rem;
  }
  .up-btn {
    width: 5rem;
    padding: 9px;
  }
  .btn-text {
    font-size: 10px;
  }
}
