body {
  font-family: "Open Sans", sans-serif;
}

.p-5 {
  color: black !important;
}

.w-116 {
  width: 116% !important;
}
//common use style
p {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.select-tab input[type="checkbox"] {
  width: 2% !important;
}
#pass-field {
  -webkit-text-secutiry: disc !important;
}
.checkbox {
  .MuiCheckbox-root {
    padding: 0px;
    padding-right: 6px;
  }
}
.main-section {
  background-color: #f7f7f8;
  height: 100vh;
}
.photo-viewer-container img,
.photo-viewer-container svg,
.photo-viewer-container {
  width: 50vw !important;
  height: 70vh !important;
}
.video-container video {
  width: 75vw !important;
  height: 75vh !important;
  outline: none !important;
}
.download-icon {
  font-size: 1.2rem;
  color: #5e44d3;
  margin-right: 0.3rem;
  margin-top: -7.5px;
}
.doc-size {
  height: 100% !important;
}
.modal-download-icon {
  position: absolute;
  top: 17px;
  left: 16px;
  font-size: 1.38rem;
  color: #5e44d3;
}
.email-elements {
  width: fit-content;
  display: flex;
  height: fit-content;
  border-radius: 1rem;
  padding: 0.2rem 0.6rem;
  background-color: rgb(225, 231, 233);
  color: black;
  .p {
    font-size: 0.4rem;
  }
}
div .border-down input {
  padding-left: 0px !important;
}
.d-1 {
  padding: 0 3rem;
}
.modal-dialog {
  min-width: fit-content;
}
.modal-dialog-full-width {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: none !important;
}
s div .error-down input {
  padding-left: 0px !important;
}
.modal-content-full-width {
  height: auto !important;
  min-height: 100% !important;
  border-radius: 0 !important;
  background-color: #ececec !important;
}

.modal-header-full-width {
  border-bottom: 1px solid #9ea2a2 !important;
}

$duration: 1s;

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}
.loading_line_wrapper {
  width: 100vw;
  height: 5px;
  position: relative;
  top: 0px;
  left: 0px;
  bottom: auto;
  right: auto;
  overflow: hidden;
}

.loading_line {
  position: relative;
  top: 0px;
  left: 0px;
  bottom: auto;
  right: auto;
  width: 100vw;
  height: 6%;
  transform-origin: 100% 0%;
  animation: kf_loading_line $duration cubic-bezier(0.645, 0.045, 0.355, 1) 0s
    infinite;

  .loading_line_inner {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: auto;
    right: auto;
    width: 100%;
    height: 100%;
    background: #000;
    transform-origin: 0% 0%;
  }
}
.loading_line_inner--1 {
  opacity: 0; //change for inner line opacity, currently 0/hidden
  animation: kf_loading_line_inner--1 $duration
    cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
}

.loading_line_inner--2 {
  opacity: 1;
  animation: kf_loading_line_inner--2 $duration
    cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
}

@keyframes kf_loading_line {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes kf_loading_line_inner--1 {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes kf_loading_line_inner--2 {
  0% {
    transform: scaleX(0);
  }
  25% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
  }
}

.video-class video {
  width: 75vw !important;
  height: 75vh !important;
  outline: none !important;
}

.modal-full .modal-content {
  min-width: 50vw;
  width: fit-content !important;
  min-height: 80vh !important;
  height: fit-content !important;
}
.loader-info {
  position: absolute;
  top: 50%;
  right: 10%;
}

select {
  border: none !important;
}
option {
  &:focus {
    background-color: #5b42ca !important;
  }
}
.modal-footer-full-width {
  border-top: 1px solid #9ea2a2 !important;
}
div .Modal-size {
  width: 115% !important;
}
div .message-box input {
  padding-left: 0px !important;
}
div .svgInline svg {
  width: 15.77rem !important;
  height: 10rem !important;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
  padding: 1rem;
}
// #a{
//   &:hover{
//     #b{
//       display: inline;
//     }
//   }
// }

#b {
  font-size: 1.6rem;
  color: #5b42ca;
  display: inline;
}
.spinner-box {
  margin-left: 17px;
  margin-right: 20px;
}
.spinner-color {
  color: #5e44d3 !important;
}
div .svgIn svg {
  width: 57vw;
  height: 75vh;
  border-top-right-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
}
//style sheet for third section
#section-3 {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.lds-dual-ring {
  display: inline-block;
  z-index: 10;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #5e44d3;
  border-color: #5e44d3 transparent #5e44d3 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.thumbnail-load {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid lightgray;
  height: 9.5rem !important;
  
}
//end style sheet of 3rd section
.image-box-size {
  width: 100% !important;
  height: 9.5rem !important;
  object-fit: cover;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.video-gallery-thumbnail {
  opacity: 0.85;
}
.play-video-icon {
  position: absolute;
  top: 70px;
  left: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
  justify-content: center;
}
@mixin common-use-style {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.card-txt {
  @include common-use-style;
  font-family: Roboto;
  font-size: 13px;
  line-height: 1.15;
  text-align: left;
  color: #5c6882;
}
.dropzone {
  outline: none !important;
}
.drag-txt {
  font-family: inherit;
  font-weight: 100;
  font-size: 1.6rem;
  padding-top: 1rem;
  color: #a7acb977;
}
.drag-border {
  border: 2px dashed #a7acb977;
  padding-top: 9rem;
}

.card-bold {
  @include common-use-style;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500 !important;
  line-height: 1.13;
  text-align: center;
  color: #464e61;
}
::-webkit-scrollbar {
  width: 6px;
}
.info-text-reset {
  position: absolute;
  font-size: 0.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  top: 4rem;
  letter-spacing: 0.049rem;
  text-align: left;
  color: #5c6882;
  padding-left: 0.1rem;
  padding-bottom: 2rem;
}
.image-name {
  font-size: 0.7rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  top: 4rem;
  letter-spacing: 0.049rem;
  text-align: left;
  color: #5c6882;
}
.selects {
  background: #ecebf1;
}
.word-wrap {
  word-wrap: break-word;
  margin-right: 25px;
}
.pdf-view {
  font-weight: bold;
  font-size: 14px;
  color: gray;
  padding-bottom: 10px;
}
.pdf-controls-div {
  display: flex;
  justify-content: space-around;
  width: 25%;
}
.modal-image-box {
  max-width: 100%;
  height: 75vh;
  border-top-right-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
  // border-radius: 1rem !important;
}
.Scroll-on-access::-webkit-scrollbar {
  width: 6px;
  display: none !important;
}
.gallery-scroll {
  max-height: 90vh !important;
  overflow-y: auto;
  overflow-x: hidden !important;
}
.gallery-file {
  padding: 10px;
  .pad {
    padding-top: 12px;
  }
}
::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: inset 0 0 6px rgba(127, 115, 143, 0.459);
  border-radius: 10px;
}
.spinner-border-sm {
  margin-bottom: 0.1rem;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: none;
  -webkit-box-shadow: inset 0 0 6px rgba(158, 114, 199, 0.808);
}
.card-size {
  width: 300px;
  height: 470px;
}
.card-sm-size {
  width: 300px;
  height: 220px;
}
.div-section {
  background-color: #ffffff;
  height: 100vh;
}
.card-body {
  display: flex;
  flex-direction: column;
}
.free-text {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold !important;
  @include common-use-style;
  line-height: 1.22;
  text-align: left;
  color: #5e47d4;
}
a {
  color: inherit;
  text-decoration: inherit;
}
.head-pos {
  position: absolute;
  right: 6.6rem;
  top: -1.5rem;
}
.flex {
  display: flex;
}
.row-size {
  width: 450px;
}
.head-txt {
  @include common-use-style;
  font-size: 18px;
  font-weight: bold !important;
  line-height: 1.22;
  text-align: left;
  color: #00123c;
}
.popup-text {
  @include common-use-style;
  font-size: 18px;
  line-height: 1.22;
  font-weight: 500 !important;
  text-align: left;
  color: #5f6268;
}
.btn-create {
  background-color: white;
  color: #5e47d4;
  border-radius: 7px;
}
.purchase-head {
  font-family: Roboto;
  font-size: 24px;
  @include common-use-style;
  line-height: 1.21;
  text-align: center;
  color: #00123c;
}
.circle-name {
  background-color: #5b42ca;
  width: 35px;
  height: 35px;
  border-radius: 19px;
}
.col-2,
.col-8 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.circle-text {
  color: white;
  position: absolute;
  top: 21%;
  left: 25%;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-img {
  padding-top: 50px;
  font-size: 70px;
  padding-bottom: 70px;
}
.logo {
  width: 145px;
  height: 32px;
}

.search-input {
  background: whitesmoke;
  border: none !important;
  border-radius: 10px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.search-field {
  position: relative;
  width: 35%;
  margin-left: auto;
  margin-right: auto;
}

.search-btn {
  position: absolute;
  top: 15px;
  right: 1rem;
  cursor: pointer;
}
.profile-icon {
  width: 31px;
  height: 31px;
  border-radius: 20px;
  color: #ffffff;
  background-image: linear-gradient(to right, #5e44d3, #398bfc);
}
.border-right {
  border-right: 2px solid black;
}
.m-top-bottom {
  margin-top: 40px;
  margin-bottom: 50px;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
//for login
.m-top {
  margin-top: 10rem;
}
.m-top-div {
  margin-top: 6.5rem;
}
.text-link {
  color: inherit;
  text-decoration: none !important;
}
.box-height {
  height: 10rem;
}
.tick-icon {
  width: 2rem;
  height: 2rem;
  color: #5e47d4;
}
input {
  padding-left: 25px;
  height: 20px;
}
@mixin common-style {
  border-radius: 14px;
  width: 400px;
  height: 56px;
  padding: 1rem;
  align-items: center;
}
.input-box-size {
  background-color: white !important;
  border-radius: 16px 16px 0px 0px !important;
  padding-top: 14px !important;
  width: 400px;
}
.main-from {
  position: relative;
}
.icon-size {
  position: absolute;
  z-index: 1000;
  top: 19px;
  left: 17px;
  width: 25px;
  height: 25px;
  color: #5c6882;
}
.country {
  // border: 1px solid red;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #ffffff;
}
.text-box1 {
  background-color: #ffffff;
  border-radius: 16px 16px 0px 0px !important;
  border-bottom: 2px solid #b6bbc7;
  @include common-style;
}

.text-box2 {
  background-image: linear-gradient(to right, #5e44d3, #398bfc);
  @include common-style;
}
.input-section {
  width: 600px;
  margin: auto;
}
.input-box {
  width: 600px;
  padding: 20px;
  background-color: #f7f7f8;
  border: 0px;
  border-radius: 10px;
}
.faqs-section {
  margin: auto;
  padding: 0rem 10rem;
}
.faqs-section .questions {
  color: #5e47d4;
  margin-left: -1rem;
}
.faqs-section .questions p {
  color: #000;
}
.faqs-section .questions li {
  cursor: pointer;
}
.add-ppl-txt {
  color: #000;
  height: 40px;
}
.sound-wave {
  width: 300px;
  height: 100px;
  border-radius: 10px;
}
.feedback-btn {
  width: 8rem !important;
  height: 3rem !important;
  line-height: 1rem !important;
}
.feedback-btn-close {
  width: 6rem !important;
  height: 2rem !important;
  line-height: 0px !important;
  align-self: flex-end !important;
}
.feedback-modal .title {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}
.feedback-modal .description {
  font-size: 14px;
  color: #5c6882;
  font-weight: 500;
}
.feedback-error {
  color: red;
}
.faq-box2 {
  background-color: #f4f4f5;
  @include common-style;
  border: 1px solid #cccccc !important;
}
@mixin btn-style {
  border-radius: 14px;
  align-items: center;
}
.update-btn {
  background-image: linear-gradient(to right, #7f66ee, #398bfc);
  width: 8rem;
  @include btn-style;
  height: 50px;
  padding: 15px;
}
.resent-email {
  background-image: linear-gradient(to right, #7f66ee, #398bfc);
  width: 10rem;
  @include btn-style;
  height: 50px;
  opacity: 0.7;
}
.resent-txt {
  font-size: 1rem;
  color: #ffffff;
  font-weight: 500;
  font-style: normal;
  text-align: center;
}
.cancel-btn {
  background-color: #ffffff;
  color: black;
  height: 40px;
  padding: 9px;
  @include btn-style;
  width: 6rem;
}
.confirm-btn {
  background-image: linear-gradient(to right, #7f66ee, #398bfc);
  color: black;
  height: 40px;
  padding: 9px;
  @include btn-style;
  width: 6rem;
}
.modal-txt,
.cancel-txt {
  padding: 14px;
}
.buy-btn {
  background-image: linear-gradient(to right, #7f66ee, #398bfc);
  width: 6.5rem;
  height: 37px;
  padding: 9px;
  @include btn-style;
}
.text-box3 {
  background-color: #ffffff;
  @include common-style;
}
.share-btn {
  background-image: linear-gradient(to right, #7f66ee, #398bfc);
  width: 5rem;
  @include btn-style;
  height: 40px;
  padding: 12px;
}
.record-btn {
  background-image: linear-gradient(to right, #7f66ee, #398bfc);
  // width: 5rem;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.box1 {
  border-left: 2px solid #b6bbc7;
  width: 400px;
  height: 60px;
  padding: 20px;
  display: flex;
  align-items: center;
}
.lock-Icon3 {
  position: absolute;
  z-index: 1000;
  top: 10rem;
  width: 1.5rem;
  height: 1.5rem;
  left: 0rem;
  color: #5c6882 !important;
}
.lock-icon {
  position: absolute;
  z-index: 1000;
  top: 175px;
  left: 17px;
  width: 25px;
  height: 25px;
  color: #5c6882;
}
.pass {
  top: 94px;
}
.box-key {
  background-color: #ededed;
  border-radius: 1rem;
}
.width-pos {
  width: 60%;
}
.wid {
  width: 28rem;
}
.eye-icon {
  position: absolute;
  z-index: 1000;
  top: 96px;
  right: 24px;
  width: 25px;
  height: 25px;
  color: #5c6882;
}
.reset-eye {
  position: absolute;
  z-index: 1000;
  top: 20px;
  right: 1.7rem;
  width: 25px;
  height: 25px;
  color: #5c6882;
}

.lock-Icon {
  position: absolute;
  z-index: 1000;
  top: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  left: 0rem;
  color: #5c6882 !important;
}
.reset-eye4 {
  position: absolute;
  z-index: 1000;
  top: 6rem;
  right: 1.7rem;
  width: 25px;
  height: 25px;
  color: #5c6882;
}
.reset-eye5 {
  position: absolute;
  z-index: 1000;
  top: 6.8rem;
  right: 1.7rem;
  width: 25px;
  height: 25px;
  color: #5c6882;
}
.reset-eye6 {
  position: absolute;
  z-index: 1000;
  top: 4.7rem;
  right: 2.7rem;
  width: 25px;
  height: 25px;
  color: #5c6882;
}
.reset-eye2 {
  position: absolute;
  z-index: 1000;
  top: 5.4rem;
  right: 1.7rem;
  width: 25px;
  height: 25px;
  color: #5c6882;
}
.reset-eye3 {
  position: absolute;
  z-index: 1000;
  top: 9.7rem;
  right: 1.7rem;
  width: 25px;
  height: 25px;
  color: #5c6882;
}
.margin-top {
  padding-top: 5rem;
}
.type-text {
  display: inline-block;
  width: 50px;
}
.file-info-name {
  width: 70%;
  margin-right: 12px;
  display: inline-block;
}
.webcam-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
}
@mixin common-text-style {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}
.login-txt {
  @include common-text-style;
  color: white;
}
.faq-txt {
  @include common-text-style;
  color: #5e47d4;
  font-weight: 500;
}
.account-txt {
  @include common-text-style;
  color: #5e47d4;
}
.eye-icon-key {
  position: absolute;
  z-index: 1000;
  top: 4.7rem;
  right: 1.7rem;
  width: 25px;
  height: 25px;
  color: #5c6882;
}
.faq-icon {
  color: #5e47d4;
  font-size: 1.3rem;
  font-weight: 600;
}
.btn-box {
  background-color: #ffffff;
  border-radius: 10px;
  width: 123px;
  height: 52px;
  padding: 10px;
  align-items: center;
}
.btn-txt {
  @include common-text-style;
  line-height: 2;
  color: #5e47d4;
}
.modal {
  padding-top: 0px;
}
.modal-margin-top {
  margin-top: 10rem;
}
.modal-size {
  padding-top: 2.4rem !important;
}
.modal-postion {
  cursor: pointer;
  width: 20rem;
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  z-index: 1000;
  right: 3rem;
  bottom: 1rem;
}

.txt {
  font-size: 14px;
  @include common-use-style;
  line-height: 2.4;
  text-align: left;
  color: #5c6882;
}
.text {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #5c6882;
}
.texts {
  font-size: 0.8em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c6882;
}
.split {
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 8rem;
  bottom: 1rem;
  overflow-x: hidden;
  padding-top: 20px;
}
.eye-icon4 {
  position: absolute;
  z-index: 1000;
  top: 340px;
  right: 24px;
  width: 25px;
  height: 25px;
  color: #5c6882;
}
.left {
  left: 0rem;
  border-right: 1px solid rgb(195, 195, 195);
}
.ht {
  height: fit-content;
}
.rights {
  right: 0rem;
}
.close-menu {
  position: absolute;
  top: 5px;
  right: 5px;
}
.menu-modal {
  cursor: pointer;
  width: 16rem;
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  z-index: 1000;
}
.upload-text {
  outline: none;
}
.MuiPaper-elevation8 {
  box-shadow: none !important;
  border: 1px solid rgb(199, 198, 198);
  border-radius: 0.5rem !important ;
}
.modal-hide {
  display: none;
}
.centered {
  width: 24rem;
  margin: auto;
}

.trash-text {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #5c6882;
}
.MuiTypography-body1 {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #5c6882;
}
.MuiSvgIcon-root {
  fill: #5e47d4;
}
.sub-text {
  font-size: 1.1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #00123c;
}
.main-div {
  display: grid;
}
.tab-size {
  height: 80vh !important;
  width: -webkit-fill-available !important;
}

.pos-tab {
  position: absolute;
  top: 46%;
  left: 56%;
}
.cloud-loader {
  position: absolute;
  top: 30%;
  left: 45%;
}
.spiner-xp {
  color: #5e47d4;
  font-size: 5rem;
  height: 5rem;
  width: 5rem;
}
.loader-pos {
  position: absolute;
  right: 50%;
  left: 50%;
}
.account-icon {
  width: 2.6rem;
  height: 2.6rem;
}
.select-icon {
  width: 2.4rem;
  height: 2.4rem;
}
.forgot-txt {
  font-size: 14px !important;
  line-height: 1.21;
  text-align: right !important;
  @include common-text-style;
  color: #00123c;
}
@mixin profile-icon-style {
  width: 1.6rem;
  height: 1.6rem;
  // position: absolute;
  // left: 20rem;
}
.write-icons {
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  top: 5.6rem;
  right: 7rem;
}
.arrow-icons {
  width: 0.6rem;
  height: 1rem;
}
.zoom-out {
  position: absolute;
  top: 17px;
  right: 16px;
  color: #5e44d3;
  font-size: 1.38rem;
  font-weight: 800;
  letter-spacing: 1px;
}
.nav-elements {
  cursor: pointer;
  color: #5e44d3;
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: 1px;
}
.cursor {
  cursor: pointer;
}
.back-icon {
  width: 0.6rem;
  height: 1rem;
  position: absolute;
  top: 18.8rem;
  right: 7.6rem;
}
.profile-icons {
  @include profile-icon-style;
  top: 5.6rem;
}
.messbox {
  @include profile-icon-style;
  top: 9.3rem;
}
.typeIcon {
  @include profile-icon-style;
  top: 12.6rem;
}
.keyIcon {
  @include profile-icon-style;
  top: 15.9rem;
}
.lockIcon {
  @include profile-icon-style;
  top: 18.8rem;
}
.login-input {
  @include common-text-style;
  text-align: left !important;
  color: #485266;
}
.lock-Icon5 {
  position: absolute;
  z-index: 1000;
  top: 90px;
  left: 1px;
  width: 25px;
  height: 25px;
  color: #5c6882;
}
.lock-icon2 {
  position: absolute;
  z-index: 1000;
  top: 258px;
  left: 17px;
  width: 25px;
  height: 25px;
  color: #5c6882;
}
.footer {
  position: fixed;
  bottom: 0rem;
}
.share-menu-icons {
  font-size: 1.4rem;
  color: black;
}
.add-people {
  font-size: 1rem;
  color: #5b42ca;
  padding: 0;
  margin: 0;
}
.border-down {
  border-bottom: 2px solid #5b42ca;
}
.lock-icon4 {
  position: absolute;
  z-index: 1000;
  top: 340px;
  left: 17px;
  width: 25px;
  height: 25px;
  color: #5c6882;
}
.error-down {
  border-bottom: 2px solid #f44336;
  input {
    padding-left: 0px;
  }
}
.header {
  padding: 0.5rem;
  background-color: #ffffff;
  border-bottom: 1px solid rgb(170, 169, 169);
}
.bor-bottom {
  border-bottom: 5px solid #5e47d4 !important;
}
.color {
  color: #5e47d4;
}
.buy-btn {
  background-image: linear-gradient(to right, #7f66ee, #398bfc);
  border-radius: 14px;
  width: 6.5rem;
  height: 37px;
  padding: 9px;
  align-items: center;
}
.footer-txt {
  @include common-text-style;
}
.text1 {
  font-size: 24px !important;
  @include common-text-style;
  text-align: left !important;
  color: #00123c;
}
.text2 {
  font-size: 14px !important;
  text-align: left !important;
  @include common-text-style;
  color: #5c6882;
}
.modal-content {
  width: 85% !important;
  border-radius: 1.5rem !important;
}

.upload-text {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #00091d;
}
.upload-icon {
  font-size: 1.4rem;
}
.highlight-text {
  font-weight: 500;
  color: #5e47d4;
}
.link-text {
  font-weight: 500;
  color: #1582ed;
}
.eye-icon2 {
  position: absolute;
  z-index: 1000;
  top: 255px;
  right: 24px;
  width: 25px;
  height: 25px;
  color: #5c6882;
}

.bold-head {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold !important;
  line-height: 1.22;
  @include common-use-style;
  text-align: left;
  color: #00123c;
}
.borderRight {
  border-right: 2px solid rgb(218, 218, 218);
}
.sec-div {
  display: flex;
  align-items: center;
}
input {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
}
.bottom-text {
  @include common-use-style;
  font-size: 14px;
  line-height: 2.4;
  text-align: left;
  color: #5c6882;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.div-from {
  width: 400px;
}

.text-area-style {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 2px solid #5b42ca;
}
.payment-img {
  width: 100px;
  height: 28px;
}
.field-size {
  width: 49%;
}
.margin-top-bottom {
  margin-top: 140px;
  margin-bottom: 190px;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.button:focus {
  border: none !important;
  outline: none !important;
}
button {
  border: none !important;
  outline: none !important;
}
.text-one {
  @include common-use-style;
  font-size: 17px;
  font-weight: 600 !important;
  line-height: 1.31;
  text-align: left;
  color: #00123c;
}

textarea:focus,
input[type="password"]:focus,
input[type="text"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="checkbox"]:focus,
input[type="color"]:focus,
select:focus,
button[type="button"]:focus,
button[type="button"]:hover,
.uneditable-input:focus {
  box-shadow: none;
  outline: 0 none;
}

/* Media queries */
@media screen and (max-width: 2400px) {
  .div-from {
    width: 460px;
  }
  .heading {
    font-size: 1.3rem;
  }
  .btn-txt {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .txt {
    font-size: 1.2rem;
  }
  .bottom-text {
    font-size: 16px;
  }
  .logo-img {
    padding-top: 50px;
    font-size: 80px;
    padding-bottom: 8rem;
  }
  .text-box2 {
    width: 460px;
    height: 4rem;
  }
  .margin-top {
    padding-top: 7rem;
  }
  .btn-box {
    width: 150px;
    height: 60px;
    padding: 0.7rem;
  }
  .row-size {
    width: 550px;
  }
  .btn-create {
    font-size: 1.3rem;
  }
  .logo {
    width: 165px;
    height: 40px;
  }
  .container {
    max-width: 1500px !important;
  }

  .card-txt {
    font-size: 1rem;
  }
  .card-bold {
    font-size: 1.3rem;
    font-weight: 600;
  }
}
@media only screen and (max-width: 1601px) {
  .container {
    max-width: 1400px !important;
  }
  .profile-icons,
  .messbox,
  .typeIcon,
  .keyIcon,
  .lockIcon {
    left: 14rem !important;
  }

  .div-from {
    width: 400px;
  }
  .txt {
    font-size: 1rem;
  }
  .btn-txt {
    font-size: 1rem;
    font-weight: 400;
  }
  .heading {
    font-size: 1.1rem;
    color: black;
  }
  .card-size {
    width: 300px;
    height: 29.6rem;
  }
  .btn-box {
    width: 120px;
    height: 43px;
    padding: 0.5rem;
  }
  .card-sm-size {
    width: 300px;
    height: 225px;
  }
  .modal-margin-top {
    margin-top: 5rem;
  }
  .card-txt {
    font-size: 0.8rem;
  }
  .card-bold {
    font-size: 1rem;
    font-weight: 600;
  }
  .text-box2 {
    width: 400px;
    height: 3.5rem;
  }
}
@media (max-width: 1370px) {
  .profile-icons,
  .messbox,
  .typeIcon,
  .keyIcon,
  .lockIcon {
    left: 12rem !important;
  }
  .modal-margin-top {
    margin-top: 3rem;
  }
  .trash-text {
    font-size: 0.8rem;
  }
  .spiner-xp {
    color: #5e47d4;
    font-size: 4rem;
    height: 3rem;
    width: 3rem;
  }
}
@media (max-width: 1281px) {
  .profile-icons,
  .messbox,
  .typeIcon,
  .keyIcon,
  .lockIcon {
    left: 10.6rem !important;
  }
  .trash-text {
    font-size: 0.7rem;
  }
}
@media only screen and (max-width: 1025px) {
  .profile-icons,
  .messbox,
  .typeIcon,
  .keyIcon,
  .lockIcon {
    left: 7rem !important;
  }
  .trash-text {
    font-size: 0.65rem;
  }
}
@media screen and (max-width: 991px) {
  .profile-icons,
  .messbox,
  .typeIcon,
  .keyIcon,
  .lockIcon {
    left: 4.7rem !important;
  }
  .modal-margin-top {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 768px) {
  .input-box-size {
    padding-top: 10px !important;
    width: 530px;
  }
  .profile-icons,
  .messbox,
  .typeIcon,
  .keyIcon,
  .lockIcon {
    left: 3.4rem !important;
  }
  .div-from {
    width: 550px;
  }
  .text-box1 {
    width: 530px;
    height: 66px;
  }
  .text-box2 {
    width: 530px;
    height: 56px;
  }
  .text-box3 {
    width: 530px;
    height: 66px;
  }
  .m-top-bottom {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .box1 {
    width: auto;
  }
  .main-div {
    margin: 25px !important;
  }
}
@media screen and (max-width: 576px) {
  .input-box-size {
    padding-top: 10px !important;
    width: 330px;
  }
  .modal-margin-top {
    margin-top: 2rem;
  }
  .div-from {
    width: 330px;
  }
  .d-1 {
    padding: 0;
    table tr td,
    table thead tr th {
      font-size: 9px;
    }
    .folder {
      font-size: 10px;
    }
  }
  .profile-icons,
  .messbox,
  .typeIcon,
  .keyIcon,
  .lockIcon {
    left: 2.7rem !important;
  }
  .text-box1 {
    width: 330px;
    height: 56px;
  }
  .text-box2 {
    width: 330px;
    height: 56px;
  }
  .text-box3 {
    width: 330px;
    height: 56px;
  }
  .m-top-bottom {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .lock-icon2 {
    top: 180px;
  }
  .lock-icon {
    top: 100px;
  }
  .main-div {
    margin: 25px !important;
  }
  .margin-top {
    padding-top: 1rem;
  }
}
@media only screen and (max-device-width: 350px) {
  .div-from {
    width: 360px; // color: #00123c !important;
  }
  .input-box-size {
    padding-top: 10px !important;
    width: 310px;
  }
  .text-box1 {
    width: auto;
    height: 56px;
  }
  .text-box2 {
    width: auto;
    height: 56px;
  }
  .text-box3 {
    width: 280px;
    height: 56px;
  }
  .m-top-bottom {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .lock-icon2 {
    top: 180px;
  }
  .lock-icon {
    top: 100px;
  }
  .main-div {
    margin: 25px !important;
  }
  .margin-top {
    padding-top: 1rem;
  }
}

@media only screen and (max-device-width: 430px) {
  .faqs-section {
    padding: 0rem 1rem;
  }
}

.folder-card-row {
  background-repeat: no-repeat;
  // padding: 15px;
  // background-position-x: center;
  margin-bottom: 2rem;
  align-self: center;
  .folder-card {
    // margin-top: 25px;
    width: 14rem;
    // height: 6rem;
    background: linear-gradient(to left, #5b42ca, #398bfc);
    border-radius: 15px;
    text-transform: uppercase;
    // padding: 3% 4% 8%;
    // margin: 0.5rem;
    padding-bottom: 3%;
    padding-left: 1rem;
    padding-top: 1rem;
    .folder-card-heading {
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      letter-spacing: 0.02em;
      // color: $color-logo;
      color: #ffffff;
      padding-bottom: 2%;
    }
    .folder-card-content {
      font-size: 14px;
      line-height: 19px;
      font-weight: bold;
      letter-spacing: -0.02em;
      color: #ffffff;
    }
    .dot-icon {
      position: relative !important;
      color: #ffffff !important;
      margin-top: -4.5rem !important;
      margin-left: 11rem !important;
      // padding-left: 2rem !important;
    }
  }
}
.folders-data {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  overflow-x: hidden !important;
  max-height: 300px !important;
}
.text-viewer {
  width: 100%;
  padding-top: 1.5rem;
  word-break: break-all;
}

.folder-icon-grid {
  font-size: 1.6rem;
  margin-right: 0.6rem;
  padding-bottom: 0.16rem;
  color: #ffffff;
}

.files-card {
  background: linear-gradient(to left, #5b42ca, #398bfc);
  border: 1px solid #5781a7;
  border-radius: 15px;
  width: 12rem;
  height: 12rem;
  margin: 0% 1rem;
  margin-top: 1rem;
  .card-img {
    height: 9rem;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: white;
    .rss-image {
      display: block;
      max-width: 100%;
      height: auto;
    }
    .doc-icon {
      color: #5781a7;
      font-weight: 600;
      justify-content: center;
      display: flex;
    }
    .pdf-icon {
      color: #7a1717;
      font-weight: 600;
      justify-content: center;
      display: flex;
    }
  }
  .card-body {
    padding: 8% 5%;
    .headings {
      .introduction {
        font-weight: bold;
        font-size: 12px;
        line-height: 17px;
        height: 23px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: white;
      }
    }
    .dot-grid-icon {
      font-size: 1.3rem !important;
      color: white !important;
      margin-top: -26px;
      margin-left: 9.5rem;
    }
  }
}
